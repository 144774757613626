import styled from "styled-components";

export const PaginationWrap = styled.div`
  margin: 0 auto;
  width: fit-content;
  .rc-pagination {
    margin-top: ${props => props?.marginTop ? `${props?.marginTop}px` : '0px'};
    display: flex;
    justify-content: center;
    height: 48px;
    li {
      font-family: "Roboto", sans-serif;
      height: 100%;
      display: flex;
      min-width: 48px;
      justify-content: center;
      margin-right: 0;
      @media (max-width: 400px) {
        min-width: 40px;
      }
      &.rc-pagination-item {
        border: none;
        background: none;
        a {
          font-weight: 700;
          font-size: 16px;
          line-height: 16px;
          color: #C1C1C1;
          margin: auto 0;
        }
        &.rc-pagination-item-active {
          a {
            color: #1B1B1B;
          }
        }
        &:focus-visible {
          outline: 2px solid orange!important;;
        }
      }
    }
    .rc-pagination-prev {
      margin-right: 24px;
      @media (max-width: 400px) {
        margin-right: 0;
        height: 40px;
        width: 40px;
      }
      svg {
        transform: rotate(180deg);
      }
      &:focus-visible {
        outline: 2px solid orange!important;
      }
      &:hover {
        #blue {
          fill: #090069;
        }
      }
    }
    .rc-pagination-next {
      margin-left: 24px;
      @media (max-width: 400px) {
        margin-left: 0;
        height: 40px;
        width: 40px;
      }
      &:focus-visible {
        outline: 2px solid orange!important;;
      }
      &:hover {
        #blue {
          fill: #090069;
        }
      }
    }
    .rc-pagination-disabled {
      svg {
        #blue {
          fill: #CCCCCC!important;
        }
      }
    }
    .rc-pagination-options {
      display: none;
    }
  }
`