import React from "react";
import "rc-pagination/assets/index.css"
import Pagination from "rc-pagination";
import locale from 'rc-pagination/es/locale/en_US';
import { PaginationArrow } from '../../utils/IconsList';
import { PaginationWrap } from "./styles/Pagination.styled";

const ComponentPagination = ({ current, total, pageSize, func, marginTop }) => (
  <PaginationWrap marginTop={marginTop}>
    <Pagination
      current={current}
      total={total}
      pageSize={pageSize}
      onChange={(num) => func(num)}
      prevIcon={<PaginationArrow />}
      nextIcon={<PaginationArrow />}
      locale={locale}
      showLessItems
      hideOnSinglePage
      showTitle
    />
  </PaginationWrap>
)

export default ComponentPagination
